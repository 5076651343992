import type { Period } from './period.js'

export function periodIntersectsWith(a: Period, b: Period): boolean {
  return a.periodStartTimestamp < b.periodEndTimestamp && b.periodStartTimestamp < a.periodEndTimestamp
}

export function periodIntersectsWithUnion(a: Period, b: Period[]): boolean {
  return b.some((b) => periodIntersectsWith(a, b))
}

export function getPeriodIntersection(a: Period, b: Period): Period | null {
  if (b.periodEndTimestamp <= a.periodStartTimestamp) return null
  if (b.periodStartTimestamp >= a.periodEndTimestamp) return null
  return {
    periodStartTimestamp: Math.max(a.periodStartTimestamp, b.periodStartTimestamp),
    periodEndTimestamp: Math.min(a.periodEndTimestamp, b.periodEndTimestamp),
  }
}
