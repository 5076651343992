import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useParams } from '@tanstack/react-router'
import { memo, useState } from 'react'
import { trpcReact } from '../../../../client'

const PIXEL_PER_DAY = 2
const INVOICE_WIDTH_PX = 100
const INVOICE_COLUMN_SPACING_PX = 5

import { amountToString, type Amount } from '@orus.eu/amount'
import type { InvoiceData } from '@orus.eu/backend/src/events/invoice-lifecycle-event'
import type { InvoiceCreationParams } from '@orus.eu/backend/src/services/invoicing/invoice-lifecycle-service'
import type { Invoice } from '@orus.eu/backend/src/views/invoicing/invoice-model'
import { PARIS } from '@orus.eu/calendar-date'
import { getPeriodDurationInDays, periodIntersectsWith } from '@orus.eu/period'
import {
  colors,
  ContentContainerBackoffice,
  FlexColumn,
  Select,
  spacing,
  Text,
  TextInputLabelWrapper,
  Tooltip,
} from '@orus.eu/pharaoh'
import { DateTime } from 'luxon'
import { formatDate } from '../../../../lib/format'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'
export default function PlatformInvoicingPage(): JSX.Element {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/invoicing' })
  const [algorithm, setAlgorithm] = useState<'v1' | 'v2'>('v1')

  const [{ existingInvoices, v1FutureInvoices, v2FutureInvoices }] =
    trpcReact.invoices.getPaymentScheduleDebugInformation.useSuspenseQuery(subscriptionId)

  const blocks = useBlocks(existingInvoices, algorithm === 'v1' ? v1FutureInvoices : v2FutureInvoices)
  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Debug de la facturation</BackofficeSectionTitle>
      <Text>Cette page permet de visualiser les factures existantes et futures d’un contrat.</Text>
      <TextInputLabelWrapper label="Algorithme de facturation">
        <Select
          size="small"
          value={algorithm}
          values={['v1', 'v2']}
          labels={{ v1: 'Facturation actuelle', v2: 'Facturation V2' }}
          onChange={(value) => setAlgorithm(value === 'v1' ? 'v1' : 'v2')}
        />
      </TextInputLabelWrapper>
      <TimelineBackground>
        {blocks.map((block) => (
          <InvoiceBlock key={block.invoiceId} block={block} />
        ))}
      </TimelineBackground>
    </ContentContainerBackoffice>
  )
}

const TimelineBackground = styled.div`
  position: relative;
`

const InvoiceBlock = memo<{ block: Block }>(function InvoiceBlock({ block }) {
  return (
    <div
      css={css`
        height: ${block.height}px;
        width: ${block.width}px;
        position: absolute;
        left: ${block.x}px;
        top: ${block.y}px;
      `}
    >
      <Tooltip
        title={
          <FlexColumn>
            <Text>Issue date : {block.issueDate}</Text>
            <Text>Due date : {block.dueDate}</Text>
            <Text>
              {block.firstDay} - {block.lastDay} ({block.version})
            </Text>
            {block.isAdjustment ? <Text>(Facture d’ajustement)</Text> : null}
          </FlexColumn>
        }
        arrowHorizontalPosition="left"
        arrowVerticalPosition="middle"
      >
        <div
          css={css`
            position: absolute;
            inset: 0;
          `}
        >
          <FlexColumn
            css={css`
              height: ${block.height}px;
              width: ${block.width}px;
              background-color: ${colorPerType[block.type]};
              justify-content: center;
              align-items: center;
              border: 1px solid white;
              padding: ${spacing[10]};
              overflow: hidden;
              position: absolute;
              inset: 0;
            `}
          >
            <Text>{amountToString(block.amount)} €</Text>
          </FlexColumn>
          <Text
            css={css`
              position: absolute;
              top: 0;
              right: 0;
            `}
            variant="caption"
          >
            {block.version}
          </Text>
        </div>
      </Tooltip>
    </div>
  )
})

const colorPerType = {
  'existing-invoice': colors.green[200],
  'future-invoice': colors.gray[200],
}

type InvoiceBlockData = {
  type: 'existing-invoice' | 'future-invoice'
  version: 'v1' | 'v2'
  invoiceId: string
  isAdjustment: boolean
  issueTimestamp: number
  dueTimestamp: number
  periodStartTimestamp: number
  periodEndTimestamp: number
  amount: Amount
}

type Block = {
  invoiceId: string
  type: 'existing-invoice' | 'future-invoice'
  x: number
  y: number
  width: number
  height: number
  firstDay: string
  lastDay: string
  issueDate: string
  dueDate: string
  amount: Amount
  version: 'v1' | 'v2'
  isAdjustment: boolean
}

type Column = {
  offset: number
  blocks: InvoiceBlockData[]
}

function useBlocks(existingInvoices: Invoice[], futureInvoices: InvoiceCreationParams[]): Block[] {
  const columns: Column[] = []

  function addToRelevantColumn(invoiceBlockData: InvoiceBlockData) {
    let column = columns.find(
      (column) => !column.blocks.some((blockData) => periodIntersectsWith(blockData, invoiceBlockData)),
    )
    if (!column) {
      column = { offset: columns.length, blocks: [] }
      columns.push(column)
    }
    column.blocks.push(invoiceBlockData)
  }

  for (const existingInvoice of existingInvoices) {
    addToRelevantColumn(
      getInvoiceBlockData(
        existingInvoice,
        existingInvoice.invoiceId,
        'existing-invoice',
        !('items' in existingInvoice) && !!existingInvoice.adjustedInvoiceId,
      ),
    )
  }

  for (const futureInvoice of futureInvoices) {
    addToRelevantColumn(
      getInvoiceBlockData(
        futureInvoice.invoiceData,
        futureInvoice.invoiceId,
        'future-invoice',
        futureInvoice.version === 2 && !!futureInvoice.adjustedInvoiceId,
      ),
    )
  }

  return columns.flatMap((column) =>
    column.blocks.map((invoiceBlockData): Block => {
      const durationInDays = getPeriodDurationInDays(invoiceBlockData)
      const daysSinceFirstPeriodStart = getPeriodDurationInDays({
        periodStartTimestamp: columns[0].blocks[0].periodStartTimestamp,
        periodEndTimestamp: invoiceBlockData.periodStartTimestamp,
      })
      return {
        invoiceId: invoiceBlockData.invoiceId,
        type: invoiceBlockData.type,
        firstDay: formatDate(invoiceBlockData.periodStartTimestamp),
        lastDay: formatDate(
          DateTime.fromMillis(invoiceBlockData.periodEndTimestamp, { zone: PARIS }).minus({ days: 1 }).toMillis(),
        ),
        issueDate: formatDate(invoiceBlockData.issueTimestamp),
        dueDate: formatDate(invoiceBlockData.dueTimestamp),
        isAdjustment: invoiceBlockData.isAdjustment,
        x: column.offset * (INVOICE_WIDTH_PX + INVOICE_COLUMN_SPACING_PX),
        y: daysSinceFirstPeriodStart * PIXEL_PER_DAY,
        width: INVOICE_WIDTH_PX,
        height: durationInDays * PIXEL_PER_DAY,
        amount: invoiceBlockData.amount,
        version: invoiceBlockData.version,
      }
    }),
  )
}

function getInvoiceBlockData(
  invoice: InvoiceData,
  invoiceId: string,
  type: 'existing-invoice' | 'future-invoice',
  isAdjustment: boolean,
): InvoiceBlockData {
  if ('items' in invoice) {
    const item = invoice.items[0]
    return {
      type,
      invoiceId,
      periodStartTimestamp: item.periodStartTimestamp,
      periodEndTimestamp: item.periodEndTimestamp,
      issueTimestamp: invoice.issueTimestamp,
      dueTimestamp: invoice.dueTimestamp,
      isAdjustment,
      amount: item.totalPremium,
      version: 'v1',
    }
  }
  return {
    type,
    invoiceId,
    periodStartTimestamp: invoice.periodStartTimestamp,
    periodEndTimestamp: invoice.periodEndTimestamp,
    issueTimestamp: invoice.issueTimestamp,
    dueTimestamp: invoice.dueTimestamp,
    isAdjustment,
    amount: invoice.totalPremium,
    version: 'v2',
  }
}
