import { PARIS } from '@orus.eu/calendar-date'
import { DateTime } from 'luxon'
import type { Period } from './period.js'

export function getRatio(period1: Period, period2: Period): number {
  return getPeriodDurationInDays(period1) / getPeriodDurationInDays(period2)
}

export function getPeriodDurationInDays(period: Period): number {
  const periodStart = DateTime.fromMillis(period.periodStartTimestamp, { zone: PARIS })
  const periodEnd = DateTime.fromMillis(period.periodEndTimestamp, { zone: PARIS })

  return periodEnd.diff(periodStart, 'days').days
}
